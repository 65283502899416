<template>

  <div class="row" ref="songForm">

    <div class="col-xs-8 offset-xs-2 col-md-8 offset-md-2 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <ValidationProvider
              vid="title"
              rules="required"
              name="The Title"
              v-slot="{ passed, failed,errors }">
              <fg-input type="text"
                        :error="failed ? errors[0]: null"
                        label="Title"
                        name="title"
                        fou
                        v-model="formData.title">
              </fg-input>
            </ValidationProvider>
            <prime-audio-uploader
              :preview-width="'100%'"
              :preview-height="'60px'"
              :elementNum="1"
              v-model="formData.song"
            ></prime-audio-uploader>
            <div class="form-group pt-4">
              <label>Is Active</label>&nbsp;
              <l-switch v-model="formData.is_active">
                <i class="fa fa-check" slot="on"></i>
                <i class="fa fa-times" slot="off"></i>
              </l-switch>
            </div>
          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/songs/list/'+ formData.lang)" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>


      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import PrimeAudioUploader from "../../components/PrimeAudioUploader";
import LSwitch from "../../components/Switch";

extend("required", {
  message: "{_field_} is required"
});

export default {
  components: {LSwitch, PrimeAudioUploader},
  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,

      formData: {
        title: "",
        song: "",
        lang: 'ar',
        is_active: true,
      },
    };
  },
    created(){
        const path = window.location.href;
        this.formData.lang = path.substring(path.lastIndexOf('/') + 1)
    },
  mounted() {

    this.loader = this.$loading.show({
      container: this.$refs.songForm
    });

    this.id = this.$route.params['id'];
    if (this.id !== undefined) {
      this.editMode = true;
      this.formTitle = "Edit Song";
      this.getSong();
    } else {
      this.editMode = false;
      this.formTitle = "Add Song";
      this.loader.hide();
    }

  },


  methods: {
    getSong() {
      let data = {
          'id' : this.id,
          'lang' : this.formData.lang
      };
      this.axios.post("songs/get", data).then((response) => {
        this.formData = response.data;
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Song Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();
        } else {
          console.error(error);
        }
      })
    },

    submit() {
      let request;
      let successMessage;
      this.submitting = true;

      if (this.editMode === true) {
        request = this.axios.put("songs/update/" + this.id, this.formData);
        successMessage = "Song Updated Successfully";
      } else {
        request = this.axios.post("songs/create", this.formData);
        successMessage = "Song Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/songs/list/"+ this.formData.lang);
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        } else {
          console.log(error.response)
        }
      }).finally(() => {
        this.submitting = false;
      })
    }

  }
}
</script>

<style>
</style>
